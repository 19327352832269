<template>
  <div style="width: 100%;background-color: #ffffff;height: 50px;line-height: 50px">建设项目综合验收现场踏勘表</div>
  <div style="height: 80%;margin-top: 30px;display: flex;justify-content: center">
    <el-upload class="upload-demo"
               :action="uploadapi"
               drag
               :show-file-list="true"
               :http-request="uploadSectionDirectory"
               :before-upload="checkType"
               :on-change="handleChange"
               :before-remove="handleRemove"
               :on-preview="listClick"
               :file-list="fileList">

      <el-icon class="el-icon--upload" title="上传">
        <UploadFilled/>
      </el-icon>
      <div class="el-upload__text" style="font-size: 16px;color: #409eff">
       点击上传
      </div>
    </el-upload>
    <el-dialog
        v-model="dialogVisible"
        title="PDF预览"
        width="72vw"
    >
      <embed id="embeds" style="width: 70.5vw; height: 60vh" frameborder="0" :src="dataurl + '#toolbar=0'"/>
      <el-button type="primary" @click="xia">下载</el-button>
    </el-dialog>
  </div>
</template>

<script>
import upload from '../../components/upload'
import {UploadFilled} from '@element-plus/icons'
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
  name: "upload",
  components: {
    UploadFilled
  },
  data() {
    return {
      uploadapi: this.$appConfig.apiUrls.ywxtApi,
      fileList: [], //文件数组
      dataurl: null,
      actionTreeNode: null,
      dialogVisible: false,
      objectId: '',
      com: 0,
      objId:null,
      FileName:'',
      ll:true,
    }
  },
  mounted() {
    this.getUploadList()
  },
  methods: {
    async getUploadList() {
      let fList = await this.$ajax.get(
          this.$appConfig.apiUrls.ywxtApi +
          '/api/File/GetFileInfos?instanceId=' + this.$route.query.instanceId+'&GroupName=验收勘探表'
      )
      console.log(fList)
      if(fList.data.IsSuccess){
        if(fList.data.Data){
          this.fileList = fList.data.Data
          if(this.fileList.length){
            for(let i =0;i<this.fileList.length;i++){
              this.fileList[i].name = this.fileList[i].FileName
            }
          }
        }
      }
    },
    // 点击上传列表
    listClick(file) {
      if(file.raw){
        this.objectId = file.raw.objectid
      }else {
        this.objectId = file.Id
        this.FileName = file.name
      }
      this.dataurl = this.$appConfig.apiUrls.ywxtApi + '/api/File/getFile?objectId=' + this.objectId,
          this.dialogVisible = true
    },
    // 文件删除之前
    handleRemove(id) {
      console.log(id)
      if (id && (id.status==="success" ||id.status==="uploading" )) {
        if(id.raw){
          this.objId = id.raw.objectid
        }else {
          this.objId = id.Id
        }
        ElMessageBox.confirm('是否删除 ' + id.name + ' ?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let res = await this.$ajax.post(
              this.$appConfig.apiUrls.ywxtApi + '/api/File/DeleteFiles',
              JSON.stringify([this.objId])
          )
          if (res.data.IsSuccess) {
            console.log(this.fileList)
            let index = this.fileList.findIndex(
                (item)=>{
                  return item.FileName == id.name
                }
            )
            this.fileList.splice(index,1)
            ElMessage({
              type: 'success',
              message: '操作成功!',
            })
          }
        })
            .catch(() => {
              console.log('123')
              ElMessage({
                type: 'info',
                message: '已取消操作',
              })
            })
        return false
      }
    },
    // 文件预上传格式
    beforeAvatarUpload(file) {
      this.objectId = file.row.objectid
      this.FileName = file.row.fileName
    },
    //自定义上传
    uploadSectionDirectory(params) {
      let instanceId = this.$route.query.instanceId
      let groupName = '验收勘探表'
      const fileName = params.file.name
      console.log('z拉拉')
      console.log(params)
      upload(
          params,
          fileName,
          groupName,
          instanceId,
          '',
          '',
          this.callback,
      )
    },
    //上传结束后事件
    callback(data) {
      //   console.log(data)
      let id = data.Data.objectId
      let that = this
      this.$ajax
          .get(
              this.$appConfig.apiUrls.ywxtApi +
              '/api/File/UploadedSuccess?objectid=' +
              data.Data.objectId
          )
          .then(function () {
            that.fileList[that.fileList.length - 1].raw.objectid = id
          })
    },
    preview() {
      if (this.objectId) {
        this.dataurl = this.$appConfig.apiUrls.ywxtApi + '/api/File/getFile?objectId=' + this.objectId,
            this.dialogVisible = true
      }
    },
    // 文件发生改变
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传之前
    checkType(file) {
      const status = file.name.split('.')[1] === 'pdf' 
      || file.name.split('.')[1] === 'png' || file.name.split('.')[1] === 'jpg'
      || file.name.split('.')[1] === 'jpeg' || file.name.split('.')[1] === 'bmp'
      if (!status) {
        this.$message({
          message: '只能上传pdf，png，jpg，jpeg，bmp文件!',
          type: 'error'
        });
      }
      return status
    },
    // 下载
    async xia(){
      let fList = await this.$ajax.get(
          this.$appConfig.apiUrls.ywxtApi +
          '/api/File/getFile?objectId=' + this.objectId + "&isDownload="+this.ll
      )
      console.log("data",fList)
        console.log("999999999",this.FileName);
         console.log("999999999",this.objectId);
        let link = document.createElement('a')
        link.href = fList.config.url,
        console.log("898989",link.href);
        link.setAttribute('download',this.FileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
  }
}
</script>

<style scoped>
.upload-demo {
  display: inline-block;
  margin-right: 10px;
  width: 300px;
  height: 150px;
}

.el-icon--upload {
  font-size: 67px;
  color: #a8abb2;
  margin: 40px 0;
}

:deep(.el-upload-list) {
  width: 360px;
}
</style>
