import axios from 'axios'
export default function upload(
	params,
	fileName,
	groupName,
	instanceId,
	webkitRelativePath,
	parentId,
	callback,
	UploadProgress
) {
	if (params && params == {}) return
	let fileChunkList = []
	let chunkSize = 2048000
	let count = 0
	let num = 0
	while (count < params.file.size) {
		fileChunkList.push({
			file: params.file.slice(count, count + chunkSize),
			chunk: num,
		})
		count += chunkSize
		num++
	}
	let result = []
	// const Performance = window.performance
	// let qpsMap = new Map()
	// const qpsController = (QPS = 1, OFFSET = 50) => async (config) => {
	// 	const now = Math.trunc(Performance.timeOrigin + Performance.now())
	// 	let { count1, ts } = qpsMap.get(config.url) || { count1: 1, ts: now }

	// 	if ((now / 1000) >> 0 <= (ts / 1000) >> 0) {
	// 		if (count1 < QPS) {
	// 			count1++
	// 		} else {
	// 			ts = 1000 * Math.ceil(ts / 1000 + 1)
	// 			count1 = 1
	// 		}
	// 	} else {
	// 		ts = now
	// 		count1 = 1
	// 	}
	// 	qpsMap.set(config.url, {
	// 		count1,
	// 		ts
	// 	})
	// 	let sleep = ts - now
	// 	sleep = sleep > 0 ? sleep + OFFSET : 0
	// 	await new Promise(resolve => setTimeout(() => resolve(), sleep))
	// 	return config
	// }
	//axios.interceptors.request.use(qpsController())
	axios.get(params.action + '/api/File/BeforeUpload?InstanceId=' + instanceId + '&groupName=' + encodeURIComponent(groupName) + '&fileName=' + encodeURIComponent(fileName) + '&relativePath=' + encodeURIComponent(webkitRelativePath) + '&size=' + params.file.size)
		.then(res => {
			// eslint-disable-next-line no-unused-vars
			fileChunkList.forEach(function (val, index) {
				const form = new FormData()
				form.append('name', fileName ? fileName : params.file.name)
				form.append('file', val.file)
				form.append('size', params.file.size)
				form.append('chunk', val.chunk)
				form.append('chunks', count)
				form.append('RelativePath', webkitRelativePath ? webkitRelativePath : params.file.webkitRelativePath)
				form.append('title', groupName)
				form.append('guid', instanceId)
				form.append('parentId', parentId)
				form.append('objectid', res.data.Data)
				//自定义额外参数
				let data = params.data
				for (let key in data) {
					form.append(key, data[key])
				}

				axios({
					url: params.action + '/api/File/UploadFile',
					method: 'post',
					data: form,
					timeout: 0,
				}).then((res) => {
					result.push(res.data)
					if (typeof UploadProgress == 'function') {
						UploadProgress(num, result.length, fileName ? fileName : params.file.name)
					}
					if (num === result.length) {
						if (callback && typeof callback == 'function') {
							callback(result[0])
						}
					}
				})
			})
		});
}
